<template>
  <div>
    <div class="pa-4">
      <navbar-profile :items="items"></navbar-profile>
    </div>
    <div class="px-5">
      <v-row no-gutters>
        <v-col cols="12" md="10">
          <div class="mt-2 txt_search">
            <v-text-field
              v-model="find"
              hide-details
              solo
              flat
              background-color="#CFE1E1"
              class="rounded-lg"
              placeholder="Search for people"
              prepend-inner-icon="mdi-magnify"
              @keyup="fetchData"
            ></v-text-field>
          </div>
        </v-col>
        <v-col cols="12" md="2" class="d-flex justify-end">
          <div class="mt-2 d-flex align-center">
            <h4 class="mr-2">Show</h4>
            <v-menu
              v-model="menuLimit"
              elevation="0"
              transition="slide-y-transition"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  min-width="60px"
                  max-width="60px"
                  outlined
                  depressed
                  class="text-capitalize"
                  v-bind="attrs"
                  v-on="on"
                >
                  <p class="ma-0 default_txt">{{ limit }}</p>
                  <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-card class="popup_radius" outlined elevation="0">
                <v-list>
                  <v-list-item link @click="changeLimit(10)">
                    <v-list-item-title>10</v-list-item-title>
                  </v-list-item>
                  <v-list-item link @click="changeLimit(50)">
                    <v-list-item-title>50</v-list-item-title>
                  </v-list-item>
                  <v-list-item link @click="changeLimit(100)">
                    <v-list-item-title>100</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-menu>
          </div>
        </v-col>
      </v-row>
      <v-row no-gutters class="mt-4" align="center">
        <v-col cols="12" md="4" class="py-2">
          <div class="d-flex align-center">
            <bullet :title="'List Kehadiran'" class="mr-2" />
            <v-menu transition="slide-y-transition" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  depressed
                  @click="importExport(import_data)"
                  class="bg_color1 rounded-lg"
                  dark
                  v-bind="attrs"
                  v-on="on"
                >
                  <div class="d-flex align-center text-capitalize">
                    <v-icon class="mr-1">
                      {{ import_data ? "mdi-import" : "mdi-export" }}
                    </v-icon>
                    {{ import_data ? "Import" : "Export" }}
                    <v-icon class="ml-1"> mdi-menu-down </v-icon>
                  </div>
                </v-btn>
              </template>
              <v-list>
                <v-list-item class="px-2">
                  <v-btn
                    block
                    depressed
                    @click="changeFunction('export')"
                    class="rounded-lg"
                  >
                    <div class="d-flex align-center text-capitalize">
                      <v-icon class="mr-1">mdi-export</v-icon>
                      Export
                    </div>
                  </v-btn>
                </v-list-item>
                <v-list-item class="px-2">
                  <v-btn
                    :disabled="
                      role != 'USER' && role != 'ASSOCIATE' ? false : true
                    "
                    block
                    depressed
                    @click="changeFunction('import')"
                    class="rounded-lg"
                  >
                    <div class="d-flex align-center text-capitalize">
                      <v-icon class="mr-1">mdi-import</v-icon>
                      Import
                    </div>
                  </v-btn>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </v-col>
        <v-col cols="12" md="8">
          <v-row no-gutters align="center" justify="end">
            <div class="col_tollbar">
              <v-btn
                depressed
                dark
                color="#CFE1E1"
                class="text-capitalize btn_tollbar rounded-lg"
                @click="changedesc(sort == '-' ? '' : '-')"
              >
                <v-icon color="#106967" v-if="sort == '-'" class="mr-2" dark>
                  mdi-sort-descending
                </v-icon>
                <v-icon color="#106967" v-if="sort == ''" class="mr-2" dark>
                  mdi-sort-ascending
                </v-icon>
                <h4 style="color: #106967">Sort</h4>
              </v-btn>
            </div>
            <div class="col_tollbar">
              <v-menu
                :close-on-content-click="false"
                v-model="filter_menu"
                transition="slide-y-transition"
                bottom
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    depressed
                    dark
                    color="#CFE1E1"
                    class="text-capitalize btn_tollbar rounded-lg"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon color="#106967" class="mr-2">
                      {{ filter ? "mdi-clock-outline" : "mdi-filter" }}
                    </v-icon>
                    <h4 style="color: #106967">
                      {{
                        filter == "today"
                          ? "Today"
                          : filter == "last_week"
                          ? "Last Week"
                          : filter == "last_month"
                          ? "Last Month"
                          : filter == ""
                          ? "All"
                          : "Custom Date"
                      }}
                    </h4>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item>
                    <div class="px-2 d-flex align-center">
                      <!-- <v-btn
                        @click="changeFilter('')"
                        block
                        class="text-capitalize"
                        text
                      > -->
                      <v-icon class="mr-2">mdi-filter</v-icon>
                      Filter
                      <!-- </v-btn> -->
                    </div>
                    <v-spacer></v-spacer>
                    <v-btn @click="filter_menu = false" icon fab x-small>
                      <v-icon small>mdi-close</v-icon>
                    </v-btn>
                  </v-list-item>
                  <v-list-item>
                    <v-btn
                      block
                      :class="
                        filter == 'today'
                          ? 'text-capitalize grey'
                          : 'text-capitalize white'
                      "
                      :dark="filter == 'today' ? true : false"
                      text
                      @click="changeFilter('today')"
                    >
                      Today
                    </v-btn>
                  </v-list-item>
                  <v-list-item>
                    <v-btn
                      block
                      :class="
                        filter == 'all'
                          ? 'text-capitalize grey'
                          : 'text-capitalize white'
                      "
                      :dark="filter == 'all' ? true : false"
                      text
                      @click="changeFilter('')"
                    >
                      All
                    </v-btn>
                  </v-list-item>
                  <!-- <v-list-item>
                    <v-btn
                      block
                      :class="
                        filter == 'last_week'
                          ? 'text-capitalize grey'
                          : 'text-capitalize white'
                      "
                      :dark="filter == 'last_week' ? true : false"
                      text
                      @click="changeFilter('last_week')"
                    >
                      Last Week
                    </v-btn>
                  </v-list-item>
                  <v-list-item>
                    <v-btn
                      block
                      :class="
                        filter == 'last_month'
                          ? 'text-capitalize grey'
                          : 'text-capitalize white'
                      "
                      :dark="filter == 'last_month' ? true : false"
                      text
                      @click="changeFilter('last_month')"
                    >
                      Last Month
                    </v-btn>
                  </v-list-item>
                  <v-list-item>
                    <v-menu
                      v-model="menuCustom_date"
                      :close-on-content-click="false"
                      max-width="290"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          block
                          :class="
                            filter == 'custom_date'
                              ? 'text-capitalize grey'
                              : 'text-capitalize white'
                          "
                          :dark="filter == 'custom_date' ? true : false"
                          text
                          @click="changeFilter('custom_date')"
                          v-bind="attrs"
                          v-on="on"
                        >
                          {{
                            custom_date
                              ? custom_date.join(" ~ ")
                              : "Custom Date"
                          }}
                        </v-btn>
                      </template>
                      <v-date-picker v-model="custom_date" range>
                      </v-date-picker>
                      <div
                        class="d-flex align-center justify-end mt-n7 mb-2 px-3"
                      >
                        <v-btn color="error" @click="menuCustom_date = false">
                          Cancel
                        </v-btn>
                        <v-btn
                          class="ml-2"
                          color="bg_color1"
                          dark
                          @click="changeFilter('custom_date', 'hide')"
                        >
                          OK
                        </v-btn>
                      </div>
                    </v-menu>
                  </v-list-item> -->
                </v-list>
              </v-menu>
            </div>
          </v-row>
        </v-col>
      </v-row>
    </div>
    <div>
      <v-skeleton-loader
        class="pa-2 elevation-0"
        type="table-tbody"
        v-if="loading"
      ></v-skeleton-loader>
      <div v-if="!loading && dataSet">
        <v-data-table
          :headers="headers"
          :items="dataSet"
          hide-default-footer
          :page.sync="page"
          :items-per-page="limit"
          class="elevation-0"
          :single-select="false"
        >
          <template v-slot:[`item.absensi.tgl_absen`]="{ item }">
            <div v-if="item.absensi" class="d-flex">
              {{
                $date(new Date(item.absensi.tgl_absen)).format("MMM DD, YYYY")
              }}
            </div>
          </template>
          <template v-slot:[`item.absensi.wkt_absen`]="{ item }">
            <div v-if="item.absensi" class="d-flex">
              {{ item.absensi.wkt_absen }}
            </div>
          </template>
          <template v-slot:[`item.absensi.wkt_pulang`]="{ item }">
            <div v-if="item.absensi" class="d-flex">
              {{ item.absensi.wkt_pulang }}
            </div>
          </template>
          <template v-slot:[`item.absensi.keterangan`]="{ item }">
            <div v-if="item.absensi">
              <v-card
                flat
                class="py-1 rounded-lg"
                :color="
                  item.absensi.keterangan == 'hadir'
                    ? '#106967'
                    : item.absensi.keterangan == 'telat'
                    ? '#BD9028'
                    : item.absensi.keterangan == 'izin'
                    ? '#052633'
                    : '#DC3545'
                "
              >
                <p
                  class="medium_txt pa-0 ma-0 text-capitalize"
                  style="color: white; text-align: center"
                >
                  {{ item.absensi.keterangan }}
                </p>
              </v-card>
            </div>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <div class="d-flex">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-if="role != 'USER' && role != 'ASSOCIATE'"
                    v-bind="attrs"
                    v-on="on"
                    small
                    icon
                    depressed
                    class="mr-2"
                    @click="updateData(item)"
                  >
                    <v-icon small> mdi-pencil </v-icon>
                  </v-btn>
                </template>
                <span>{{ item.absensi ?  'Update Kehadiran' : 'Tambah Kehadiran' }}</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    small
                    icon
                    depressed
                    class="mr-2"
                    @click="history(item)"
                  >
                    <v-icon small> mdi-eye </v-icon>
                  </v-btn>
                </template>
                <span>Lihat Histori Kehadiran</span>
              </v-tooltip>
              <!-- <v-btn small icon depressed color="red" @click="deleteData(item)">
                <v-icon small> mdi-delete </v-icon>
              </v-btn> -->
            </div>
          </template>
        </v-data-table>
        <div class="text-center pa-3">
          <v-pagination
            color="#052633"
            v-model="page"
            @input="fetchData"
            :length="last_page"
          ></v-pagination>
        </div>
      </div>
    </div>
    <div class="pa-2">
      <dialog-large
        :d_large="d_large"
        :type="d_type"
        @close="d_large = false"
        :title="d_title"
        :inventory="inventory"
        :line="line"
        @refetch="fetchData"
      />
      <dialog-medium
        :d_medium="d_create"
        :type="d_type"
        @close="d_create = false"
        :title="d_title"
        :inventory="inventory"
        :line="line"
        @refetch="fetchData"
      />
      <dialog-small
        :d_small="d_small"
        @refetch="fetchData"
        @close="d_small = false"
        :inventory="inventory"
        :type="d_type"
        :title="d_title"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import bullet from "../../components/Etc/bullet.vue";
import NavbarProfile from "../../components/navbarProfile/navbarProfile.vue";
export default {
  components: { bullet, NavbarProfile },
  name: "listAttendence",
  computed: {
    dateRangeText() {
      return this.dates.join(" ~ ");
    },
    ...mapState({
      role: (state) => state.role,
      profile: (state) => state.profile,
    }),
  },
  data() {
    return {
      menuLimit: false,
      loading: false,
      d_large: false,
      d_create: false,
      d_small: false,
      d_type: "",
      d_title: "",
      inventory: "",
      line: "",
      find: "",
      page: 1,
      limit: 10,
      dataSet: null,
      last_page: null,
      total_page: null,
      sort: "-",
      filter: "",
      filter_menu: false,
      menuCustom_date: false,
      custom_date: "",
      import_data: false,
      items: [
        {
          text: "Home",
          disabled: false,
          href: "/",
        },
        // {
        //   text: "List Klien",
        //   disabled: false,
        //   href: "/admin/mk/client-attendence",
        // },
        {
          text: "List Kehadiran",
          disabled: true,
          href: "#",
        },
      ],
      headers: [
        { text: "Nama", value: "nama", width: "30%", sortable: false },
        { text: "ID Karyawan", value: "id" },
        { text: "Tanggal", value: "absensi.tgl_absen", width: "15%" },
        { text: "Jam Masuk", value: "absensi.wkt_absen", sortable: false },
        {
          text: "Jam Pulang",
          value: "absensi.wkt_pulang",
          sortable: false,
        },
        { text: "Kehadiran", value: "absensi.keterangan", sortable: false },
        { text: "Action", value: "actions", sortable: false },
      ],
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.loading = true;
      var d = new Date();
      var from_d = null;
      var to_d = "";
      var sort = "";
      if (this.filter == "") {
        to_d = null;
        from_d = null;
      } else if (this.filter == "last_month") {
        to_d = this.$date(d.toLocaleDateString()).format("YYYY-MM-DD");
        d.setMonth(d.getMonth() - 1);
        from_d = this.$date(d.toLocaleDateString()).format("YYYY-MM-DD");
      } else if (this.filter == "today") {
        to_d = null;
        from_d = this.$date(d.toLocaleDateString()).format("YYYY-MM-DD");
      } else if (this.filter == "last_week") {
        to_d = this.$date(d.toLocaleDateString()).format("YYYY-MM-DD");
        d.setMonth(d.getDay() - 7);
        from_d = this.$date(d.toLocaleDateString()).format("YYYY-MM-DD");
      } else {
        to_d = this.custom_date[1];
        from_d = this.custom_date[0];
      }
      if (from_d) {
        if (this.sort == "-") {
          sort = "-klien_karyawan.created_at";
        } else {
          sort = "klien_karyawan.created_at";
        }
      } else {
        if (this.sort == "-") {
          sort = "-created_at";
        } else {
          sort = "created_at";
        }
      }
      let path = `sysadmin/admin/absensi/list/${this.$route.params.idClient}?page[size]=${this.limit}&page[number]=${this.page}&filter[nama]=${this.find}&sort=${sort}`;
      if (from_d) {
        path = `sysadmin/admin/absensi/list/${this.$route.params.idClient}?page[size]=${this.limit}&page[number]=${this.page}&tgl_from=${from_d}&filter[nama]=${this.find}&sort=${sort}`;
      }
      let data = {
        path: path,
      };
      this.$store
        .dispatch("officer/getData", data)
        .then((data) => {
          this.dataSet = data.data;
          this.last_page = data.last_page;
          this.total_page = data.total;
          this.loading = false;
        })
        .catch((e) => {
          this.$store.dispatch("notify", {
            msg: e.message,
            type: "error",
          });
          this.loading = false;
        });
    },
    changedesc(item) {
      this.sort = item;
      this.fetchData();
    },
    changeFilter(item, add) {
      if (item == "custom_date" && !add) {
        this.menuCustom_date = true;
      } else if (item == "custom_date" && add == "hide") {
        this.menuCustom_date = false;
      }
      this.filter = item;
      this.filter_menu = false;
      this.fetchData();
    },

    changeFunction(item) {
      if (item == "export") {
        this.downloadeExport();
        this.importExport(item);

        this.import_data = false;
      } else {
        this.importExport(item);
        this.import_data = true;
      }
    },
    importExport(item) {
      if (item == "import") {
        this.inventory = [];
        this.d_type = "i_attendence";
        this.d_title = "Import Attendence";
        this.d_large = true;
        this.line = true;
      } else {
      }
    },
    downloadeExport() {
      let data = {
        path: `sysadmin/admin/absensi/export`,
        body: "",
      };
      this.$store
        .dispatch("fileData/postApi", data)
        .then((data) => {
          window.open(data.data.path);
        })
        .catch((e) => {
          this.$store.dispatch("notify", {
            msg: e.message,
            type: "error",
          });
        });
    },
    updateData(item) {
      this.inventory = item;
      this.d_type = item.absensi ? "u_attendence" : "c_attendence";
      this.d_title = item.absensi ? "Edit Kehadiran" : "Tambah Kehadiran";
      this.d_create = true;
      this.line = true;
    },
    deleteData(item) {
      this.inventory = {
        data: item,
        path: `sysadmin/user/akun/delete`,
        deleteType: "Attendence",
      };
      this.d_type = "delete";
      this.d_title = "Hapus Kehadiran";
      this.d_small = true;
      this.line = true;
    },
    history(item) {
      this.$router.push(
        `/admin/mk/client-attendence/attendence/${this.$route.params.idClient}/history/${item.id}`
      );
    },
    changeLimit(val) {
      this.limit = val;
      this.fetchData();
    },
  },
};
</script>

<style scoped>
.v-pagination__item {
  box-shadow: none !important;
}
.v-pagination__navigation {
  box-shadow: none !important;
}
</style>
